/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { CityStateLookupDataModelApiModel } from '../models/city-state-lookup-data-model-api-model';
import { GeoJsonDataModelApiModel } from '../models/geo-json-data-model-api-model';
import { getAllStateBoundries } from '../fn/lookup/get-all-state-boundries';
import { GetAllStateBoundries$Params } from '../fn/lookup/get-all-state-boundries';
import { getClosestToLatLng } from '../fn/lookup/get-closest-to-lat-lng';
import { GetClosestToLatLng$Params } from '../fn/lookup/get-closest-to-lat-lng';
import { getStateAndCity } from '../fn/lookup/get-state-and-city';
import { GetStateAndCity$Params } from '../fn/lookup/get-state-and-city';
import { getStateBoundriesByCoordinates } from '../fn/lookup/get-state-boundries-by-coordinates';
import { GetStateBoundriesByCoordinates$Params } from '../fn/lookup/get-state-boundries-by-coordinates';
import { getStateBoundriesByStateId } from '../fn/lookup/get-state-boundries-by-state-id';
import { GetStateBoundriesByStateId$Params } from '../fn/lookup/get-state-boundries-by-state-id';

@Injectable({ providedIn: 'root' })
export class ItkGeoLookupApi extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `lookupControllerGetAllStateBoundries()` */
  static readonly LookupControllerGetAllStateBoundriesPath = '/v1/lookup/boundries';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllStateBoundries()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllStateBoundries$Response(params?: GetAllStateBoundries$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<GeoJsonDataModelApiModel>>> {
    return getAllStateBoundries(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllStateBoundries$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllStateBoundries(params?: GetAllStateBoundries$Params, context?: HttpContext): Observable<Array<GeoJsonDataModelApiModel>> {
    return this.getAllStateBoundries$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<GeoJsonDataModelApiModel>>): Array<GeoJsonDataModelApiModel> => r.body)
    );
  }

  /** Path part for operation `lookupControllerGetStateBoundriesByCoordinates()` */
  static readonly LookupControllerGetStateBoundriesByCoordinatesPath = '/v1/lookup/boundries/{lat}/{lng}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getStateBoundriesByCoordinates()` instead.
   *
   * This method doesn't expect any request body.
   */
  getStateBoundriesByCoordinates$Response(params: GetStateBoundriesByCoordinates$Params, context?: HttpContext): Observable<StrictHttpResponse<GeoJsonDataModelApiModel>> {
    return getStateBoundriesByCoordinates(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getStateBoundriesByCoordinates$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getStateBoundriesByCoordinates(params: GetStateBoundriesByCoordinates$Params, context?: HttpContext): Observable<GeoJsonDataModelApiModel> {
    return this.getStateBoundriesByCoordinates$Response(params, context).pipe(
      map((r: StrictHttpResponse<GeoJsonDataModelApiModel>): GeoJsonDataModelApiModel => r.body)
    );
  }

  /** Path part for operation `lookupControllerGetStateBoundriesByStateId()` */
  static readonly LookupControllerGetStateBoundriesByStateIdPath = '/v1/lookup/boundries/{stateId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getStateBoundriesByStateId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getStateBoundriesByStateId$Response(params: GetStateBoundriesByStateId$Params, context?: HttpContext): Observable<StrictHttpResponse<GeoJsonDataModelApiModel>> {
    return getStateBoundriesByStateId(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getStateBoundriesByStateId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getStateBoundriesByStateId(params: GetStateBoundriesByStateId$Params, context?: HttpContext): Observable<GeoJsonDataModelApiModel> {
    return this.getStateBoundriesByStateId$Response(params, context).pipe(
      map((r: StrictHttpResponse<GeoJsonDataModelApiModel>): GeoJsonDataModelApiModel => r.body)
    );
  }

  /** Path part for operation `lookupControllerGetClosestToLatLng()` */
  static readonly LookupControllerGetClosestToLatLngPath = '/v1/lookup/closest/{lat}/{lng}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getClosestToLatLng()` instead.
   *
   * This method doesn't expect any request body.
   */
  getClosestToLatLng$Response(params: GetClosestToLatLng$Params, context?: HttpContext): Observable<StrictHttpResponse<CityStateLookupDataModelApiModel>> {
    return getClosestToLatLng(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getClosestToLatLng$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getClosestToLatLng(params: GetClosestToLatLng$Params, context?: HttpContext): Observable<CityStateLookupDataModelApiModel> {
    return this.getClosestToLatLng$Response(params, context).pipe(
      map((r: StrictHttpResponse<CityStateLookupDataModelApiModel>): CityStateLookupDataModelApiModel => r.body)
    );
  }

  /** Path part for operation `lookupControllerGetStateAndCity()` */
  static readonly LookupControllerGetStateAndCityPath = '/v1/lookup/city/{stateId}/{city}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getStateAndCity()` instead.
   *
   * This method doesn't expect any request body.
   */
  getStateAndCity$Response(params: GetStateAndCity$Params, context?: HttpContext): Observable<StrictHttpResponse<CityStateLookupDataModelApiModel>> {
    return getStateAndCity(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getStateAndCity$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getStateAndCity(params: GetStateAndCity$Params, context?: HttpContext): Observable<CityStateLookupDataModelApiModel> {
    return this.getStateAndCity$Response(params, context).pipe(
      map((r: StrictHttpResponse<CityStateLookupDataModelApiModel>): CityStateLookupDataModelApiModel => r.body)
    );
  }

}
